<template>
  <div class="d-container">
    <div class="columns">
      <div class="column is-1 hidden-xs"></div>
      <div class="column hidden-xs">
        <div class="d-title">
          {{post.title}}
        </div>
        <div class="d-front">
          <font-awesome-icon :icon="['fas', 'folder-open']" class="d-front-item"></font-awesome-icon>
          <span class="d-front-item">
        <router-link :to="{name:'home',query:{page:1,cat:post.category}}">
          {{post.category}}
        </router-link>
      </span>
          <font-awesome-icon :icon="['fas', 'tags']" class="d-front-item"></font-awesome-icon>
          <span v-for="(item,index) in post.tags" :key="index" class="d-front-item">
        <router-link :to="{name:'home',query:{page:1,tag:item}}">
          #{{item}}
        </router-link>
      </span>
          <span style="float: right; margin-right: 30px;">
        <font-awesome-icon :icon="['fas', 'calendar-alt']" class="d-front-item"></font-awesome-icon>
            <span class="d-front-item" v-if="post.date">{{post.date.substr(0,10)}}</span>
<!--      <span class="d-front-item">{{post===undefined?"":post.date.substr(0,10)}}</span>-->
      </span>
        </div>
        <mavon-editor
            ref="mavon"
            :toolbars-flag="false"
            :ishljs="true"
            default-open="preview"
            v-model="post.content"
            :subfield = false
            :toolbarsFlag = "false"
            :editable="false"
            :scrollStyle="false"
            :navigation="false"
            @change="init"
        />
<!--        <v-md-preview-->
<!--            :text="post.content"-->
<!--        ></v-md-preview>-->
      </div>
      <div class="column is-3 hidden-xs">
        <div class="d-toc-container">
          <span class="d-toc-title">目录</span>
          <div id="navigation"></div>
        </div>
      </div>
      <!--移动端-->
      <div class="column is-2 show-xs"></div>
      <div class="column show-xs">
        <div class="d-title">
          {{post.title}}
          <!--          <button class="pure-button" style="float: right; font-size: 50%;">返回</button>-->
        </div>
        <div class="d-front">
          <font-awesome-icon :icon="['fas', 'folder-open']" class="d-front-item"></font-awesome-icon>
          <span class="d-front-item">
        <router-link :to="{name:'home',query:{page:1,cat:post.category}}">
          {{post.category}}
        </router-link>
      </span>
          <font-awesome-icon :icon="['fas', 'tags']" class="d-front-item"></font-awesome-icon>
          <span v-for="(item,index) in post.tags" :key="index" class="d-front-item">
        <router-link :to="{name:'home',query:{page:1,tag:item}}">
          #{{item}}
        </router-link>
      </span>
          <span style="float: right; margin-right: 30px;">
        <font-awesome-icon :icon="['fas', 'calendar-alt']" class="d-front-item"></font-awesome-icon>
            <span class="d-front-item" v-if="post.date">{{post.date.substr(0,10)}}</span>
            <!--      <span class="d-front-item">{{post===undefined?"":post.date.substr(0,10)}}</span>-->
      </span>
        </div>
        <mavon-editor
            ref="mavon"
            :toolbars-flag="false"
            :ishljs="true"
            default-open="preview"
            v-model="post.content"
            :subfield = false
            :toolbarsFlag = "false"
            :editable="false"
            :scrollStyle="false"
            :navigation="false"
            @change="init"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DetailPage",
  data(){
    return{
      "allPost":[],
      "post":{},
    }
  },
  methods:{
    // eslint-disable-next-line no-unused-vars
    init(value,render){
      // this.build();
      this.buildNavigation(render);
    },
    refreshData(){
      let usePosts=this.allPosts.slice(0,this.allPosts.length);
      let that=this;
      usePosts=usePosts.filter(function (post){
        return post.permalink===that.$route.params.permalink;
      })
      this.post=usePosts[0];
    },
    buildNavigation(html) {
      // TODO 目录的长度有问题
      let a = document.getElementById('navigation')
      a.style.cursor="pointer";
      a.innerHTML = html
      const nodes = a.children
      let newDoms = []
      if (nodes.length) {
        for (let i = 0; i < nodes.length; i++) {
          const id = (nodes[i].children && nodes[i].children.length) ? nodes[i].children[0].id : ''
          judageH(nodes[i], i, nodes, id)
        }
      }
      function judageH(node, i, nodes, domId) {
        // Mod 只匹配h2和h3目录
        const reg = /^H[2-3]{1}$/
        if (!reg.exec(node.tagName)) { // 把不是h标签的都过滤掉
          node.style.display = 'none'
        } else {
          node.classList.add('navigator-item')
          const nodeArr = node.innerHTML.split('</a>')
          const id = domId
          const content = nodeArr[1]
          let children = node.childNodes
          for (let index = children.length - 1; index >= 0; index--) {
            node.removeChild(children[index])
          }
          const a = document.createElement('a')
          a.id = id
          a.innerHTML = content
          // node.style.cursor="pointer";
          // node.onmouseover=function (){
          //   a.style.cursor="pointer";
          // }
          // node.onmouseout=function (){
          //   a.style.cursor="none";
          // }
          // a.style.cursor="hand";
          node.appendChild(a)
          // Mod 修改目录内联样式
          if(node.tagName==="H2"){
            node.style.fontSize="16px";
            node.style.color="#545454";
          }else if(node.tagName==="H3"){
            node.style.fontSize="13px";
            node.style.color="#747474";
          }
          node.onclick = function() {
            // MOD 锚点不修改URL  (准确的描述需求，问题，可以节省很多很多的时间)
            document.querySelector('#'+ this.children[0].id).scrollIntoView(true);
          }
          newDoms.push(node)
        }
      }
      const sliceDoms = [] // 归类好的节点树
      newDoms.forEach((dom, i) => { // 把标题归类 每部分的标题组合到一起
        const level = dom.tagName.substr(1)
        const upLevel = newDoms[i - 1] ? newDoms[i - 1].tagName.substr(1) : ''
        if (upLevel) {
          if (level > upLevel) {
            sliceDoms[sliceDoms.length - 1].push(dom)
          } else if (level > sliceDoms[sliceDoms.length - 1][0].tagName.substr(1)) {
            sliceDoms[sliceDoms.length - 1].push(dom)
          } else {
            sliceDoms.push([dom])
          }
        } else {
          sliceDoms.push([dom])
        }
      })
      sliceDoms.forEach(doms => {
        const thisTitleMaxId = doms[0].tagName.substr(1)
        doms.forEach(dom => {
          const domHeadingLevel = dom.tagName.substr(1) - thisTitleMaxId + 1
          dom.classList.add('heading_' + domHeadingLevel)
        })
      })
    }
  },
  created() {
    this.$axios.get("/data.json").then((resp) => {
      this.allPosts=resp.data;
      this.refreshData();
    })
  },
  watch:{
    $route(){
      this.refreshData();
    }
  },
}
</script>

<style scoped>
.d-container{
  margin-top: 30px;
}
.d-title{
  font-size: 22px;
  font-weight: bolder;
}
a {
  color: #494949;
  text-decoration: none;
}
a:hover {
  color: #777;
}
.d-front {
  margin: 10px 5px;
  color: #777777;
}
.d-front-item{
  font-size: 13px;
  margin-right: 5px;
}
.d-toc-container{
  position: fixed;
  padding-left: 15px;
  border-left: #CCCCCC 3px solid;
}
/*美化滚动条*/
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  background-color: #F5F5F5;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
  background-color: #c8c8c8;
}
.d-toc-title{
  font-size: 22px;
  font-weight: bolder;
}
#navigation{
  max-height:400px;
  overflow: auto;
  width: 250px;
}
.hidden-xs{
  display: inline;
}
.show-xs{
  display: none;
}
/*使用媒体查询，小屏幕切换到无目录模式*/
@media screen and (max-width: 768px) {
  .hidden-xs{
    display: none;
  }
  .show-xs{
    display: inline;
  }
}
</style>
